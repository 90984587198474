import React, { useEffect, useState } from 'react'
import moment from 'moment'

import useDashboard from './useDashboard'
import { Info as InfoIcon } from '../svg/components'
import { parseLevel } from '../utils/string'
import { parseValueInt } from '../utils/CoinUtils'
import { CAN_SCORE_SHOW, CAN_USER_INVITE, FINISH_PERIOD_DATA } from '../services/constants'
import { formatNumber } from '../utils/numberUtils'
import { maxLevel, LevelData, calculateNextInvite } from '../utils/LevelUtils'
import { docsUrl } from '../utils/ShareUtils'


function Dashboard() {

  const dashboard = useDashboard()
  const { data, eventItemList, periodData, timeValue, showDay, totalData } = dashboard


  const renderNextInvite = () => {
    if (data === null || data === undefined) {
      return '--'
    }
    const level = data.level
    const inviteCount = data.inviteCount
    return calculateNextInvite(level, inviteCount)
  }

  const renderNextLevel = () => {
    if (data === null || data === undefined) {
      return '--'
    }
    const level = data.level
    if (level === 0) {
      return parseLevel(1)
    }
    const nextLevel = level + 1
    if (nextLevel > maxLevel) {
      return parseLevel(maxLevel)
    }
    return parseLevel(nextLevel)
  }


  return <div className='flex flex-col flex-1 px-6 py-5 max-h-body overflow-auto'>
    <div className='flex flex-row p-6 space-x-6 bg-[#1E1E1E] rounded-3xl'>
      <span className='font-sans text-lg font-medium leading-5 text-white'>Epoch #{periodData?.roundNumber !== undefined ? periodData?.roundNumber : '--'}</span>
      {/*<span className='font-sans text-lg font-medium leading-5 text-transparent bg-yellow-gradient bg-clip-text'>Ending in {showDay?`${timeValue[0]}:${timeValue[1]}:${timeValue[2]}`:`${timeValue[1]}:${timeValue[2]}:${timeValue[3]}`}</span>*/}
      <span className='font-sans text-lg font-medium leading-5 text-transparent bg-yellow-gradient bg-clip-text'>Ending in {`${timeValue[0]}:${timeValue[1]}:${timeValue[2]}:${timeValue[3]}`}</span>

      <div className='flex flex-row flex-wrap text-left'>
        <span className='font-sans text-lg leading-5 text-white'>Total E/ACC Points Pool in EPOCH #{periodData?.roundNumber !== undefined ? periodData?.roundNumber : '--'}:&nbsp;</span>
        <span className='font-sans text-lg font-bold leading-5 text-white'>{totalData?.totalWeightedTime ? parseValueInt(totalData?.totalWeightedTime.toString()) : '--'}</span>
      </div>
      {/* <div className='flex flex-row flex-wrap text-left'>
        <span className='font-sans text-lg leading-5 text-white'>Total Participates(Beta invitation only):&nbsp;</span>
        <span className='font-sans text-lg font-bold leading-5 text-white'> {totalData?.totalParticipants ? parseValueInt(totalData?.totalParticipants.toString()) : '--'}</span>
      </div> */}
    </div>
    <div className='flex flex-1 flex-row mt-7'>
      <div className='flex flex-col space-y-[26px]'>
        <div className="flex font-sans flex-row items-left">
          <div className="flex flex-col">
            <div className="w-full rounded-[24px]"
              style={{
                background: "linear-gradient(to top right, #1e1e1e 58%, #f9611f 100%)",
                backgroundSize: "cover", backgroundPosition: "center", overflow: "hidden",
              }} >
              <div className="font-sans flex flex-col items-start p-6">
                <div className='mb-[18px] font-bold text-lg leading-[21.6px] text-white uppercase'>EPOCH #{periodData?.roundNumber !== undefined ? periodData?.roundNumber : '--'}</div>
                <div className='flex flex-row'>
                  <div className="flex flex-col items-start w-[185px] space-y-2">
                    <div className="text-xs leading-[14px] font-medium text-white uppercase">E/ACC Points earned</div>
                    <div className='text-6xl font-score font-bold bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text text-transparent ' title={data?.todaytorepoint ?? undefined}>{data?.todaytorepoint ? formatNumber(data?.todaytorepoint).omit : '--'}</div>
                    <div className="flex flex-row items-center">
                      <span
                        className='inline-flex text-sm cursor-pointer'
                        onClick={() => { window.open(docsUrl) }}>
                        <InfoIcon />
                      </span>
                      <span className='ml-1 text-[10px] leading-[10px] text-[#FFA800]'>this is before normalization</span>
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-[185px] space-y-2" title={CAN_USER_INVITE ? '' : 'Not opened in internal test'}>
                    <div className="flex flex-row items-center" >
                      <div className="mr-2 text-xs leading-[14px] font-medium text-white uppercase">Rank in EPOCH #{periodData?.roundNumber !== undefined ? periodData?.roundNumber : '--'}</div>
                      <span
                        className='inline-flex text-sm cursor-pointer'
                        onClick={() => { window.open(docsUrl) }}>
                        <InfoIcon />
                      </span>
                    </div>
                    <div className='text-6xl font-score font-bold bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text text-transparent '>{(data?.todayrank ?? '--')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex font-sans flex-row items-left">
          <div className="flex flex-col">
            <div className="w-full bg-[#1E1E1E] rounded-[24px]" >
              <div className="font-sans flex flex-col items-start p-6">
                <div className='font-bold text-lg leading-[21.6px] text-white uppercase'>My Points </div>
                  <span className='text-[11px] leading-[13.2px] text-[#FFA800] max-w-[360px] text-left'>Your points earned in Epoch#{FINISH_PERIOD_DATA} have been normalized and added to your account.</span> 
                {/* <span className='text-[11px] leading-[13.2px] text-[#FFA800] max-w-[360px] text-left'>E/ACC POINTS YOU EARNED IN EPOCH#3 WILL BE NORMALIZED AND ADDED SOON.</span> */}
                <div className='flex flex-row mt-[18px]'>
                  <div className="flex flex-col items-start w-[185px] space-y-2">
                    <div className="text-xs leading-[14px] font-medium text-white uppercase">E/ACC Points earned</div>
                    <div className='text-6xl font-score font-bold bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text text-transparent ' title={(CAN_SCORE_SHOW && data?.historepoint) ?? undefined}>{CAN_SCORE_SHOW ? (data?.historepoint ? formatNumber(data?.historepoint).omit : '--') : 'N/A'}</div>
                  </div>
                  <div className="flex flex-col items-start w-[185px] space-y-2">
                    <div className="text-xs leading-[14px] font-medium text-white uppercase">Overall Ranking</div>
                    <div className='text-6xl font-score font-bold bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text text-transparent '>{CAN_SCORE_SHOW ? (data?.rank ?? '--') : 'N/A'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex font-sans flex-row items-left">
          <div className="flex flex-col">
            <div className="w-full bg-[#1E1E1E] rounded-[24px]" >
              <div className="font-sans flex flex-col items-start p-6">
                <div className='mb-[18px] font-bold text-lg leading-[21.6px] text-white uppercase'>Reputation score</div>
                <div className='flex flex-row'>
                  <div className="flex flex-col items-start w-[185px] space-y-2">
                    <div className="flex flex-row items-center" >
                      <div className="mr-2 text-xs leading-[14px] font-medium text-white uppercase">Reputation score</div>
                      <span
                        className='inline-flex text-sm cursor-pointer'
                        onClick={() => { window.open(docsUrl) }}>
                        <InfoIcon />
                      </span>
                    </div>
                    <div className='text-6xl font-score font-bold bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text text-transparent '>{CAN_SCORE_SHOW ? (data?.score ?? '--') : 'N/A'}</div>
                  </div>
                  <div className="flex flex-col items-start w-[185px] space-y-2">
                    <div className="text-xs leading-[14px] font-medium text-white text-nowrap uppercase">{renderNextInvite()} more referrals to {renderNextLevel()}</div>
                    <div className='text-6xl font-score font-bold text-nowrap bg-gradient-to-r from-[#FFA957] via-[#D0C1F1] to-[#B795FF] bg-clip-text text-transparent uppercase'>
                      {parseLevel(data?.level)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex font-sans flex-col flex-1 items-left ml-6 bg-[#1e1e1e] rounded-[24px] pl-4 pr-6 py-5">
        {/* Header */}
        <div className="text-sm font-light text-white mb-3" style={{ textAlign: 'left' }}>
          <div className='flex flex-row items-center justify-between'>
            <span className='text-sm leading-[14px]'>EVENTS</span>
            <div className='flex flex-row items-center'>
              <span
                className='inline-flex text-sm cursor-pointer'
                onClick={() => { window.open(docsUrl) }}
              >
                <InfoIcon />
              </span>
              <span className='ml-1 text-[10px] text-[#FFA800] leading-[10px]'>points in this chart are before normalization</span>
            </div>
          </div>
          <div className="flex font-sans w-full text-xs text-[#9C9C9C] mb-4 mt-4">
            <div style={{ width: '15%', textAlign: 'left' }}>Date</div>
            <div style={{ width: '20%', textAlign: 'left' }}>App</div>
            <div style={{ width: '50%', textAlign: 'left' }}>Event</div>
            <div style={{ width: '15%', textAlign: 'right' }}>Points</div>
          </div>
          {
            eventItemList && eventItemList?.map(item => {
              return (
                <div key={item.id} className="flex w-full text-white text-sm mb-2">
                  <div style={{ width: '15%', textAlign: 'left' }}>{moment(item.endTime).format('MM/DD/YY HH:mm')}</div>
                  <div style={{ width: '20%', textAlign: 'left' }}>{item.app}</div>
                  <div style={{ width: '50%', textAlign: 'left' }}>{item.eventType}</div>
                  <div style={{ width: '15%', textAlign: 'right' }}>+{item.pointsChange}</div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  </div>
}

export default Dashboard